import Vue from 'vue'
import '@/assets/less/init.less'
import 'element-ui/lib/theme-chalk/index.css'
import '/node_modules/cropperjs/dist/cropper.min.css'
import MetaInfo from 'vue-meta-info'
import * as buffer from 'buffer'

if (typeof window.global === 'undefined') {
  window.global = window
}
if (typeof window.Buffer === 'undefined') {
  window.Buffer = buffer.Buffer
}
import {
  Carousel,
  CarouselItem,
  Upload,
  Dialog,
  Input,
  InputNumber,
  Radio,
  RadioGroup,
  Slider,
  Select,
  Option,
  Tooltip,
  MessageBox,
  Popover,
  Switch,
  Image,
  ColorPicker,
  Form,
  FormItem,
  Button,
  Checkbox, Progress
} from 'element-ui'
import App from './App.vue'
import router from './router'
import store from './store'

import { getToken } from '@/utils/token'
import Msg from '@/components/Msg'
import Purchase from '@/components/PurchaseModal'
import Retention from '@/components/RetentionModal'
import Login from '@/components/LoginModal'
import Verify from '@/components/VerifyModal'
import BindPhoneModal from '@/components/BindPhoneModal'
import BindWechatModal from '@/components/BindWechatModal'
import BindOrderModal from "@/components/BindOrderModal";
import UnBindPhoneModal from "@/components/UnBindPhoneModal";
Vue.config.productionTip = false
Vue.component(Carousel.name, Carousel)
Vue.component(CarouselItem.name, CarouselItem)
Vue.component(Upload.name, Upload)
Vue.component(Dialog.name, Dialog)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Radio.name, Radio)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(Slider.name, Slider)
Vue.component(Select.name, Select)
Vue.component(Option.name, Option)
Vue.component(Tooltip.name, Tooltip)
Vue.component(Popover.name, Popover)
Vue.component(MessageBox.name, MessageBox)
Vue.component(Switch.name, Switch)
Vue.component(Image.name, Image)
Vue.component(ColorPicker.name, ColorPicker)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Button.name, Button)
Vue.component(Checkbox.name, Checkbox)
Vue.component(Progress.name, Progress)
Vue.use(Msg)
Vue.use(MetaInfo)

Vue.use(Purchase, { router, store })
Vue.use(Retention, { router, store })
Vue.use(Login, { router, store })
Vue.use(Verify, { router, store })
Vue.use(BindPhoneModal, { router, store})
Vue.use(BindWechatModal, { router, store})
Vue.use(BindOrderModal, { router, store})
Vue.use(UnBindPhoneModal, {router, store})

router.beforeEach((to, from, next) => {
  if (Object.keys(to.query).includes('qz_gdt')) {
    localStorage.setItem('sougou_tag', `${to.query['qz_gdt']}`)
  }
  if (getToken()) {
    store.dispatch('updateUserInfo').catch(e => {})
  }
  next()
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
